import { PersonMatcher } from '../person/person-matcher';
import { ChannelMessageResponse } from './channel-message';
import { ChannelNotifications, ConditionalChannelNotificationType } from './channel-notifications';


export enum ChannelTyperStatus {
    Typing = 'typing',
    Talking = 'talking',
    None = 'none',
}

export enum ChannelType {
    /**
     * Admin Defined Channel (ADC)
     */
    Channel = 'channel',
    /**
     * User Defined Channel (UDC)
     */
    People = 'people',
    /**
     * Channel created to report a safety observation
     */
    SafetyObservation = 'safety-observation',
    /**
     * Channel created to facilitate support conversations with a 
     * weavix support agent
     */
    SupportTicket = 'support-ticket',
    /**
     * Channel created when an API calls the admin api endpoint
     * POST: /api/admin/channel-message
     */
    AdminApi = 'admin-api',
}

/**
 * These channel types are stored at and isolated to the account level
 */
export const AccountChannelTypes = [ChannelType.Channel, ChannelType.SafetyObservation, ChannelType.AdminApi];

/**
 *  These channel types are stored in the master database and are cross-account accessible
 */
export const GlobalChannelTypes = [ChannelType.People, ChannelType.SupportTicket];

export type ArchivedMap = { [personId: string]: Date | string };

export type AccessInfo = {
    from?: Date;
    to?: Date;
};
export type AccessMap = { [personId: string]: AccessInfo };

export interface Channel extends PersonMatcher {
    id?: string;
    accountId?: string;
    deleted?: boolean;
    created?: Date | string;
    creatorId?: string; // used for checking connection when getting channels to see if they can subscribe or just show the accept/decline prompt
    conditionalNotificationOverrides?: ConditionalChannelNotificationType[];
    defaultNotification?: ChannelNotifications;
    isReadOnly?: boolean;
    name?: string;
    allPeople?: string[];
    fullAccess?: boolean;
    everyone?: boolean;
    tagCraft?: boolean;
    preferencesDisabled?: boolean;
    readOnlySenders?: string[];

    /**
     * Access dates for individual people who join late or are removed from the channel.
     */
    access?: AccessMap;

    /**
      * A number that increases every time a message is sent on the channel.
      * This is effectively the total number of messages on the channel. The
      * number may be lower than the actual number of messages on the channel
      * if the channel existed before we implemented this field.
      * 
      * Previously we used the totalMessageCount field for this, but we made a new
      * field so that we could have backwards compatibility while we transitioned
      * to a new way of calculating badge counts.
    */
    sequence?: number;

    /**
      * A number representing how many messages a user has read on the channel.
      * This cannot be larger than the sequence field above.
      * 
      * The difference between sequence and readSequence is the number of unread messages.
      * 
      * This is present on UDCs only, and is used to show which messages have been read
      * by other users. To see how many messages the current user has read, see the
      * `readSequence` (singular) field on the ChannelResponse interface below.
      * 
      * ADCs can have a ton of users so we do not support this feature on ADCs.
      * 
      * Previously we used the messageCounts field for this, but we made a new
      * field so that we could have backwards compatibility while we transitioned
      * to a new way of calculating badge counts.
    */
    readSequences?: { [userId: string]: number; };
    type?: ChannelType;
    typers?: ChannelTyper[];
    roips?: Array<{ id: string; radio: string; }>;
    updated?: Date | string;
    radioChannel?: number;
    radioSquelch?: number;
    lastAutoReplies?: { [userId: string]: AutoReplyEvent };
    folderId?: string;
    tags?: string[];
    teamsChatId?: string;
    task?: ChannelTask;

    /**
     * channelImageUrl only applies to channelType === ChannelType.AdminApi
     * A URL to an image that represents the channel.
     */
    channelImageUrl?: ChannelImageUrl;
    /**
     * responseUrl only applies to channelType === ChannelType.AdminApi
     * A URL that weavix will POST to when a user responds in the channel.
     */
    responseUrl?: string;

    /**
     * When data retention policies are applied, any messages older than this date
     * have been deleted. On UDC (people) channels, this is a map of userIds to dates
     * because users may be on different home companies and therefore have different
     * data retention policies. On ADC (channel) channels, this is a single date for
     * the whole channel since only account members exist on the channel.
     */
    archived?: Date | string | ArchivedMap;
}

export interface ChannelImageUrl {
    externalUrl: string;
    internalUrl: string;
}

export enum TaskChannelType {
    SafetyObservation = 'safetyObservation',
    SupportTicket = 'supportTicket',
}

export type ChannelTask = SafetyObservationTask | SupportTicketTask;
export type SafetyObservationTaskState = 'initializing' | 'ai-processing' | 'awaiting-reporter-feedback' | 'done';
export interface SafetyObservationTask {
    id?: string;
    facilityId: string;
    location?: number[];
    geofenceId?: string;
    ticketNumber?: number;
    state: SafetyObservationTaskState;
}

export interface SupportTicketTask {
    ticketId?: string;
    status: 'pending' | 'open' | 'closed';
}

export interface ChannelTyper {
    personId: string;
    status: ChannelTyperStatus;
    expires: Date;
}

/**
 * The data published on the Topic.AccountChannelTypers mqtt topic.
 */
export interface ChannelTypers {
    typers: ChannelTyper[];
}

export interface AutoReplyEvent {
    sentAt: Date,
    messageHash: string,
}

export interface ChannelResponse extends Channel {
    notification?: ChannelNotifications;

    /**
     * This field is not stored on the channel record itself in the database.
     * Instead, it's stored on the channel preference for the user/channel.
     * This field is populated from the channel preference and represents the 
     * readSequence value for the logged-in user.
     * 
     * We use this field to calculate the unread badge count for a channel for
     * the current user. This is present on both UDCs and ADCs, unlike the
     * `readSequences` (plural) field on the Channel interface above.
     */
    readSequence?: number;

    lastMessages?: ChannelMessageResponse[];
    favoriteIndex?: number;
    isSnoozed?: boolean;
}

export interface ChannelTyperRequest {
    status: ChannelTyperStatus;
    expires?: Date;
}

export interface CreateGroupRequest {
    id?: string;
    name?: string;
    defaultNotification?: ChannelNotifications;
    people?: string[];
    tags?: string[];
    new?: boolean;
    teamsChatId?: string;
    task?: ChannelTask;
    type?: ChannelType;
}

export interface UpdateGroupRequest {
    name?: string;
    people?: string[];
    teamsChatId?: string;
    access?: { [personId: string]: { from?: Date; to?: Date; } };
}

export interface CreateChannelRequest {
    name?: string;
    facilityIds?: string[];
    conditionalNotificationOverrides?: ConditionalChannelNotificationType[];
    defaultNotification?: ChannelNotifications;
    preferencesDisabled?: boolean;
    people?: string[];
    peopleTags?: string[];
    peopleCrafts?: string[];
    tags?: string[];
    isReadOnly?: boolean;
    readOnlySenders?: string[];
    teamsChatId?: string;
    type?: ChannelType;
    task?: ChannelTask;
}

export interface ChannelReadResponse {
    channelId?: string;
    readSequence?: number;
}
