import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import { Avatar } from 'components/avatar/avatar.model';
import { PeopleOverviewChart } from 'components/chart/people-overview-chart.model';
import { PeopleOverviewDataService } from 'components/chart/people-overview-data.service';
import { environment } from 'environments/environment';
import { Subject, Subscription } from 'rxjs';
import { Craft } from 'weavix-shared/models/craft.model';
import { AnyBadgeEvent, EventType } from '@weavix/models/src/badges/event';
import { Person } from 'weavix-shared/models/person.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { UserProfile } from 'weavix-shared/models/user.model';
import { Geofence } from 'weavix-shared/models/weavix-map.model';
import { PermissionAction } from 'weavix-shared/permissions/permissions.model';
import { AccountService } from 'weavix-shared/services/account.service';
import { AlertService, ServiceError } from 'weavix-shared/services/alert.service';
import { BadgeService } from 'weavix-shared/services/badge.service';
import { ChannelService } from 'weavix-shared/services/channel.service';
import { CraftService } from 'weavix-shared/services/craft.service';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { GeofenceService } from 'weavix-shared/services/geofence.service';
import { MapService } from 'weavix-shared/services/map.service';
import { PersonService } from 'weavix-shared/services/person.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { PubSubService } from 'weavix-shared/services/pub-sub.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { css } from 'weavix-shared/utils/css';
import { AutoUnsubscribe, getMeetingWindowFeatures, getRadioWindowFeatures, MEETING_WINDOW_TARGET, RADIO_WINDOW_TARGET, Utils } from 'weavix-shared/utils/utils';
import { BEACON_EVENT_TYPES, CONFINED_SPACE_EVENT_TYPES, GEOFENCE_EVENT_TYPES, LEVEL_EVENT_TYPES, mapExitToDisconnectEvent, RowEventType } from '../../../weavix-map/event-log/event.service';
import { ChannelResponse } from '@weavix/models/src/channel/channel';

@AutoUnsubscribe()
@Component({
    selector: 'app-person-detail',
    templateUrl: './person-detail.component.html',
    styleUrls: ['./person-detail.component.scss', '../map-detail-view.scss'],
    providers: [],
})
export class PersonDetailComponent implements OnChanges, OnInit, OnDestroy {
    @Input() person: Person;
    @Input() removeBackButton: boolean = false;
    @Input() map: google.maps.Map;
    @Output() closeOutput: EventEmitter<void> = new EventEmitter();

    avatarInput: Avatar = {
        height: 106,
        width: 106,
        borderWidth: 3,
    };

    private loaded: Promise<void> = new Promise<void>(r => this.loadedResolver = r);
    private loadedResolver: () => void;
    private badgeEventsSub: Subscription;
    userProfile: UserProfile;
    loading: boolean = true;
    chartsLoading: boolean = true;
    rowEventTypes: RowEventType[];
    visibleOnMap: boolean = false;
    badgeEventsHistory: AnyBadgeEvent[];
    badgeEvents: Subject<AnyBadgeEvent> = new Subject<AnyBadgeEvent>();
    geofences: {[id: string]: Geofence};
    crafts: {[id: string]: Craft};
    personCrafts: string;

    peopleOverviewChart = PeopleOverviewChart;
    personMap: Map<string, Person>;
    chartStyleOptions = {
        verticalLayout: true,
        backgroundColor: css.colors.ALMOST_BLACK,
        foregroundColor: css.colors.ALMOST_BLACK,
    };
    peopleOverviewDataSub: Subscription;

    lightTheme: boolean;
    canVideo: boolean = false;
    canDirectory = false;

    constructor(
        private pubSubService: PubSubService,
        private accountService: AccountService,
        private mapService: MapService,
        private profileService: ProfileService,
        private alertService: AlertService,
        private geofenceService: GeofenceService,
        private craftService: CraftService,
        private badgeService: BadgeService,
        private translationService: TranslationService,
        private podService: PeopleOverviewDataService,
        private facilityService: FacilityService,
        private channelService: ChannelService,
    ) { }

    async ngOnInit() {
        this.lightTheme = ThemeService.getLightTheme();
        this.userProfile = await this.profileService.getUserProfile(this);
        this.crafts = Utils.toObjectMap(await this.craftService.getAll(this), x => x.id);
        this.subscribeToDvrState();
        this.loadedResolver();
        this.canDirectory = this.profileService.hasPersonPermission(PermissionAction.ViewDirectory, this.accountService.getAccountId(), this.person);
    }

    async ngOnChanges(changes: SimpleChanges) {
        await this.loaded;
        if (changes.person?.previousValue?.id !== changes.person?.currentValue?.id) {
            await this.setup();
        }
    }

    async ngOnDestroy() {
        this.stopSubs();
    }

    private stopSubs() {
        if (this.peopleOverviewDataSub) {
            this.peopleOverviewDataSub?.unsubscribe();
            this.podService.dataCache = null;
        }
    }

    async radioClick() {
        let channel: ChannelResponse;
        try {
            channel = await this.channelService.addGroup(this, { people: [this.person.id] });
        } catch (e) {
            console.error('Failed to start/find channel');
        } finally {
            const queryParams = channel != null ? `?id=${channel.id}` : '';
            window.open(`${environment.radioUrl}/radio${queryParams}`, RADIO_WINDOW_TARGET, getRadioWindowFeatures());
        }
    }

    async videoClick() {
        window.open(`${environment.radioUrl}/meeting/join/new/account/${this.accountService.getAccountId()}?personId=${this.person.id}`, MEETING_WINDOW_TARGET, getMeetingWindowFeatures());
    }

    handleClose() {
        this.closeOutput.emit();
    }

    private async setup(): Promise<void> {
        this.loading = true;
        this.chartsLoading = true;
        try {
            this.personMap = new Map([[this.person.id, this.person]]);
            this.personCrafts = (this.person.crafts ?? []).map(x => this.crafts[x]?.name).join(', ');

            this.visibleOnMap = this.presentOnMap();

            this.canVideo = this.profileService.hasPermissionInAnyFacility(PermissionAction.UseVideoChat);

            await Promise.all([
                this.subscribeToBadgeEvents(),
                this.getEvents(),
                this.subscribeToPeopleOverviewData(),
            ]);
        } catch (e) {
            this.alertService.sendServiceError(e, ServiceError.Get, 'person.person');
        } finally {
            this.loading = false;
        }
    }

    async subscribeToPeopleOverviewData() {
        this.stopSubs();
        if (this.podService.dataCache) {
            // If we are inside the people overview, we already have data
            this.chartsLoading = false;
            return;
        }
        const { from, to } = this.mapService.dvrActiveTimeRange;
        this.peopleOverviewDataSub = (await this.podService.startSubscription(this, from, to, this.facilityService.getCurrentFacility(), this.person.id))
            .subscribe(() => {
                this.chartsLoading = false;
            });
    }

    private presentOnMap(event?: AnyBadgeEvent): boolean {
        if (event) return PersonService.isActiveEvent(event, this.mapService.dvrTimestamp);
        else return PersonService.hasActiveDevice(this.person);
    }

    async getEvents() {
        const fences = await this.geofenceService.getAll(this);
        this.geofences = fences.reduce((acc, f) => { acc[f.id] = f; return acc; }, {});

        await this.setBadgeEventHistory();

        this.rowEventTypes = [
            ...BEACON_EVENT_TYPES,
            ...GEOFENCE_EVENT_TYPES,
            ...CONFINED_SPACE_EVENT_TYPES,
            ...LEVEL_EVENT_TYPES,
            RowEventType.FormSubmission,
            RowEventType.MobileLogout,
        ];
    }

    private async setBadgeEventHistory() {
        const toDate = this.mapService.dvrPlaybackState.inPlaybackMode ? new Date(this.mapService.dvrTimestamp) : null;
        this.badgeEventsHistory = await this.badgeService.getEvents(this, this.person.id, null, toDate)
            .then(mapExitToDisconnectEvent);
    }

    private async subscribeToBadgeEvents() {
        if (this.badgeEventsSub) this.badgeEventsSub.unsubscribe();
        this.badgeEventsSub = (await this.pubSubService.subscribe<AnyBadgeEvent>(this, Topic.AccountPersonBadgeEvent, [this.accountService.getAccountId(), this.person.id]))
            .subscribe(async (message) => {
                await this.loaded;
                if (this.mapService.dvrPlaybackMode) return;
                this.badgeEvents.next(message.payload);
            });
    }

    private subscribeToDvrState() {
        Utils.safeSubscribe(this, this.mapService.dvrPlaybackState$).subscribe(async x => {
            if (this.loading) return;
            if (!x.playback) this.setup();
        });
    }

    eventNameLookUp() {
        return <T extends AnyBadgeEvent>(row: T) => {
            if ([EventType.EntryEnter, EventType.EntryExit, EventType.EntryAttendantIn, EventType.EntryAttendantOut].includes(row.type)) {
                return row.itemName;
            } else if (row.type === EventType.FormSubmission) {
                return row.formName;
            } else if (row.type === EventType.AirReadingSubmission) {
                return this.translationService.getImmediate('items.entry.airReading');
            } else if (row.type === EventType.BeaconEnter || row.type === EventType.BeaconExit || row.type === EventType.BeaconDisconnect) {
                return row['beaconLevel'] !== undefined ? `L${row['beaconLevel']}: ${row['beaconName']}` : `${row['beaconName']}`;
            } else if (row.type === EventType.GeofenceEnter) {
                return this.geofenceService.getGeofenceEventString(EventType.GeofenceEnter, this.geofences?.[row['geofenceId']]?.name ?? row['geofenceName']);
            } else if (row.type === EventType.GeofenceExit) {
                return this.geofenceService.getGeofenceEventString(EventType.GeofenceExit, this.geofences?.[row['geofenceId']]?.name ?? row['geofenceName']);
            } else if (row.type === EventType.GeofenceDisconnect) {
                return this.geofenceService.getGeofenceEventString(EventType.GeofenceDisconnect, this.geofences?.[row['geofenceId']]?.name ?? row['geofenceName']);
            } else if (row.type === EventType.LevelEnter) {
                return `${this.translationService.getImmediate('shared.level.enter')} ${row.level}`;
            } else if (row.type === EventType.LevelExit) {
                return `${this.translationService.getImmediate('shared.level.exit')} ${row.level}`;
            } else if (row.type === EventType.MobileLogout) {
                return this.translationService.getImmediate('events.sign-out');
            } else if (row.type === EventType.Moving || row.type === EventType.Stationary) {
                return this.translationService.getImmediate(`geofence.${row.type}`);
            } else {
                return this.translationService.getImmediate('generics.unknown');
            }
        };
    }
}

