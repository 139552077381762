import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { chain, indexOf, pickBy, without } from 'lodash';

import { Company } from './models/company.model';
import { Craft } from './models/craft.model';
import { CompanyService } from './services/company.service';
import { CraftService } from './services/craft.service';
import { Utils } from './utils/utils';

declare const countries: any; // assets/data/countries.js included in angular.json
declare const states: any; // assets/data/states.js included in angular.json

export interface LookUpMap {
    [key: string]: any[];
}

export class CustomValidators {
    // Source: https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
    // eslint-disable-next-line no-useless-escape
    public static readonly phoneRegex: RegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    // eslint-disable-next-line max-len, no-control-regex, no-useless-escape
    public static readonly emailRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
    private static readonly zipCodeRegex = /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *\d[A-Z]\d)$/;
    private static readonly numberRegex: RegExp = /^[0-9]+$/;
    private static readonly anyNumberRegex: RegExp = /^-?[0-9]\d*(\.\d+)?$/;

    static extractEmail(value: string | null | undefined): string | undefined {
        if (!value) return undefined;
        if (CustomValidators.emailRegex.test(value)) {
            return value;
        }

        // Extract email address from text copied from Outlook, which will be in the following format:
        // Some Name <foo@bar.domain>
        const startCharIndex = value.lastIndexOf('<');
        const endCharIndex = value.lastIndexOf('>');
        const hasCharsInCorrectOrder = startCharIndex > -1 && endCharIndex > -1 && startCharIndex < endCharIndex;
        if (hasCharsInCorrectOrder) {
            const extractedValue = value.substring(startCharIndex + 1, endCharIndex);
            return CustomValidators.emailRegex.test(extractedValue) ? extractedValue : undefined;
        }

        return undefined;
    }

    static phoneNumber(control: AbstractControl): ValidationErrors | null {
        if (!control.value || (CustomValidators.phoneRegex.test(control.value) && isValidPhoneNumber(control.value))) {
            return null;
        } else {
            return { phoneNumber: true };
        }
    }

    static emailOrPhone(control: AbstractControl): ValidationErrors | null {
        const emailOrPhone = control.value;
        if (emailOrPhone === '' || CustomValidators.emailRegex.test(emailOrPhone) || CustomValidators.phoneRegex.test(emailOrPhone)) return null;
        return { emailOrPhone: true };
    }

    static number(control: AbstractControl): ValidationErrors | null {
        if (control.pristine) return null;
        if (control.value === '' || CustomValidators.numberRegex.test(control.value)) {
            return null;
        } else {
            return { number: true };
        }
    }

    static minOrZero(minVal: number): ValidatorFn {
        return (control: AbstractControl) => {
            const value = parseInt(control.value, 10);
            if (value !== 0 && value < minVal) return { greaterThan: { min: minVal } };
            return null;
        };
    }

    static anyNumber(control: AbstractControl): ValidationErrors | null {
        if (control.value === '' || CustomValidators.anyNumberRegex.test(control.value)) {
            return null;
        } else {
            return { number: true };
        }
    }

    static anyDate(control: AbstractControl): ValidationErrors | null {
        if (!control.value || !isNaN(new Date(control.value).getTime())) {
            return null;
        } else {
            return { number: true };
        }
    }

    static minTotalSelections(min: number, trimEmpty: (boolean | ((v: any) => boolean)) = false): ValidatorFn {
        return (control: AbstractControl) => {
            let value = (<{ids: string[], folders: string[], facilities: string[]}>(control.value || { ids: [], folders: [], facilities: [] }));
            if (trimEmpty) {
                if (trimEmpty === true) trimEmpty = Utils.notEmpty;
                value = { ids: (value.ids || []).filter(trimEmpty), folders: (value.folders || []).filter(trimEmpty), facilities: (value.facilities || []).filter(trimEmpty) };
            }
            const totalLength = value.ids.length + value.folders.length + value.facilities.length;
            return totalLength < min ? { minSelLength: true } : null;
        };
    }

    static zipCode(control: AbstractControl): ValidationErrors | null {
        if (control.value === '' || CustomValidators.zipCodeRegex.test(control.value)) {
            return null;
        } else {
            return { zipCode: true };
        }
    }

    static state(control: AbstractControl): ValidationErrors | null {
        const statesList = states;
        if (!statesList || !control.value) return { state: true };

        const invalidState = !statesList.some(state => state.name.toLowerCase() === control.value.toLowerCase());
        return invalidState ? { state: true } : null;
    }

    static country(control: AbstractControl): ValidationErrors | null {
        const countriesList = countries;
        if (!countriesList || !control.value) return { country: true };

        const invalidCountry = !countriesList.some(country => country.name.toLowerCase() === control.value.toLowerCase());
        return invalidCountry ? { country: invalidCountry } : null;
    }

    static minArrayLength(min: number, trimEmpty: (boolean | ((v: any) => boolean)) = false): ValidatorFn {
        return (control: AbstractControl) => {
            let value = (<any[]>(control.value || []));
            if (trimEmpty) {
                if (trimEmpty === true) trimEmpty = Utils.notEmpty;
                value = value.filter(trimEmpty);
            }
            return value.length < min ? { minArrayLength: true } : null;
        };
    }

    static uniqueInArray(values: any[]): ValidatorFn {
        return (control: AbstractControl) => {
            return indexOf(values, control.value) > -1 ? { uniqueInArray: true } : null;
        };
    }

    static uniqueInLookUpMap(map: LookUpMap, idKey: string): ValidatorFn {
        const values = chain(map).values().flatten().uniq().value() as any[];
        map = pickBy(map, (val) => (val || []).length > 0);

        return (control: AbstractControl) => {
            const idField = control.parent ? control.parent.get(idKey) : null;
            const isEdit = idField && idField.value;
            let validValues = [...values];

            if (isEdit) {
                const exceptions = Object.prototype.hasOwnProperty.call(map, idField.value) ? map[idField.value] : null;
                validValues = exceptions ? without(validValues, ...exceptions) : validValues;
            }

            return indexOf(validValues, control.value) > -1 ? { uniqueInLookUpMap: true } : null;
        };
    }

    static fieldsEqual(keys: string[], ignoreCase = false): ValidatorFn {
        return (group: FormGroup) => {
            const valueToMatch = group.get(keys[0]).value;
            for (const k of keys) {
                const object = new Object();
                object[`${keys[0]}MisMatch`] = true;
                if (ignoreCase) {
                    if (group.get(k).value?.toLowerCase() !== valueToMatch?.toLowerCase()) return object;
                } else {
                    if (group.get(k).value !== valueToMatch) return object;
                }
            }
            return;
        };
    }

    static checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
        const pass = group.get('pin').value;
        const confirmPass = group.get('confirmPin').value;
        return pass === confirmPass ? null : { notSame: true };
      };

    static lessThan(maxControlKey: string): ValidatorFn {
        return (minControl: AbstractControl) => {
            if (!minControl.parent) return;

            const maxControl = minControl.parent.controls[maxControlKey];

            if (minControl.pristine) return;

            const maxVal = parseInt(maxControl.value, 10);
            const minVal = parseInt(minControl.value, 10);

            if (isNaN(maxVal) || isNaN(minVal)) return;

            if (minVal >= maxVal) return { lessThan: { max: maxVal } };
        };
    }

    static greaterThan(minControlKey: string): ValidatorFn {
        return (maxControl: AbstractControl) => {
            if (!maxControl.parent) return;

            const minControl = maxControl.parent.controls[minControlKey];

            if (maxControl.pristine) return;

            const minVal = parseInt(minControl.value, 10);
            const maxVal = parseInt(maxControl.value, 10);

            if (isNaN(maxVal) || isNaN(minVal)) return;

            if (maxVal <= minVal) return { greaterThan: { min: minVal } };
        };
    }

    static greaterThanOrEqualTo(minControlKey: string, minValStr?: string): ValidatorFn {
        return (maxControl: AbstractControl) => {
            if (!maxControl.parent) return;

            const minControl = maxControl.parent.controls[minControlKey];

            if (maxControl.pristine) return;

            const minVal = parseInt(minControl.value, 10);
            const maxVal = parseInt(maxControl.value, 10);

            if (isNaN(maxVal) || isNaN(minVal)) return;

            if (maxVal < minVal) return { greaterThanOrEqual: { min: minValStr ? minValStr : minVal } };
        };
    }

    static lessThanOrEqualTo(maxControlKey: string, maxValStr?: string): ValidatorFn {
        return (minControl: AbstractControl) => {
            if (!minControl.parent) return;

            const maxControl = minControl.parent.controls[maxControlKey];

            if (minControl.pristine) return;

            const maxVal = parseInt(maxControl.value, 10);
            const minVal = parseInt(minControl.value, 10);

            if (isNaN(maxVal) || isNaN(minVal)) return;

            if (minVal > maxVal) return { lessThanOrEqual: { max: maxValStr ? maxValStr : maxVal } };
        };
    }

    static dateAfterOrEqualTo(minControlKey: string, minValStr?: string): ValidatorFn {
        return (maxControl: AbstractControl) => {
            if (!maxControl.parent) return;

            const minControl = maxControl.parent.controls[minControlKey];

            if (maxControl.pristine) return;

            const minDate = new Date(minControl.value);
            const minVal = minDate.getTime();
            const maxVal = new Date(maxControl.value).getTime();

            if (isNaN(maxVal) || isNaN(minVal)) return;

            if (maxVal < minVal) return { dateAfterOrEqualTo: { max: minValStr ? minValStr : minDate.toISOString() } };
        };
    }

    static startDateBeforeEndDate(startControlKey: string, endControlKey: string): ValidatorFn {
        return (group: AbstractControl) => {
            const startVal = (group as FormGroup).controls?.[startControlKey]?.value;
            const endVal = (group as FormGroup).controls?.[endControlKey]?.value;
            if (!startVal || !endVal) return;

            const startTime: number = startVal.getTime();
            const endTime: number = endVal.getTime();

            if (isNaN(startTime) || isNaN(endTime)) return;

            if (endTime < startTime) return { invalidDates: true };
        };
    }

    static startTimeBeforeEndTime(startControlKey: string, endControlKey: string): ValidatorFn {
        return (group: AbstractControl) => {
            const startVal = (group as FormGroup).controls?.[startControlKey]?.value;
            const endVal = (group as FormGroup).controls?.[endControlKey]?.value;
            if (!startVal || !endVal) return;

            const startHoursMinutes = startVal.split(':');
            const startTime: Date = new Date();
            startTime.setUTCHours(startHoursMinutes[0], startHoursMinutes[1]);
            const start: number = startTime.getTime();

            const endHoursMinutes = endVal.split(':');
            const endTime: Date = new Date();
            endTime.setUTCHours(endHoursMinutes[0], endHoursMinutes[1]);
            const end: number = endTime.getTime();

            if (isNaN(start) || isNaN(end)) return;

            if (end <= start) return { invalidDates: true };
        };
    }

    static companyNameValidator(component: any, companyService: CompanyService, editCompany?: Company): AsyncValidatorFn {
        return async (control: AbstractControl) => {
            let res = await companyService.getAll(component);
            if (editCompany) res = res.filter(r => r.name !== editCompany.name);
            if (res.filter(c => c.name.toLocaleLowerCase() === control.value.toLocaleLowerCase()).length > 0) {
                return { companyExists: true };
            } else {
                return null;
            }
        };
    }

    static craftNameValidator(component: any, craftService: CraftService, editCraft?: Craft): AsyncValidatorFn {
        return (control: AbstractControl) => {
            return craftService.getAll(component).then(res => {
                if (editCraft) res = res.filter(r => r.name !== editCraft.name);
                if (res.filter(c => c.name.toLocaleLowerCase() === control.value.toLocaleLowerCase()).length > 0) {
                    return { craftNameExists: true };
                } else {
                    return null;
                }
            });
        };
    }

    static craftCodeValidator(component: any, craftService: CraftService, editCraft?: Craft): AsyncValidatorFn {
        return async (control: AbstractControl) => {
            let res = await craftService.getAll(component);
            if (editCraft) res = res.filter(r => r.code !== editCraft.code);
            if (res.filter(c => c.code.toLocaleLowerCase() === control.value.toLocaleLowerCase()).length > 0) {
                return { craftCodeExists: true };
            } else {
                return null;
            }
        };
    }

    static atLeastOne(validator: ValidatorFn, controls: string[]): ValidatorFn {
        return (group: AbstractControl) => {
            if (!controls) controls = Object.keys((group as FormGroup).controls);
            const hasAtLeastOne = group && (group as FormGroup).controls && controls.some(k => !validator((group as FormGroup).controls[k]));
            return hasAtLeastOne ? null : { atLeastOne: true };
        };
    }

    static conditionalValidator(predicate: () => boolean, validator: ValidatorFn): ValidatorFn {
        return (control: AbstractControl) => {
          if (!control.parent) return null;
          if (predicate()) return validator(control);
          return null;
        };
    }

    static atLeastOneTrueValidator(group: FormGroup): ValidationErrors | null {
        return Object.keys(group.controls).some(x => group.controls[x].value === true) ? null : { notValid: true };
     }

    static verifyMatchingInputs(group: FormGroup, key1: string, key2: string): ValidatorFn {
        return (control: AbstractControl) => {
            if (group.get(key1)?.value === group.get(key2)?.value) {
                return null;
            } else {
                return { notMatching: true };
            }
        };
    }
}
