import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { AccountService } from './account.service';
import { CacheContext, HttpService } from './http.service';
import { PubSubService } from './pub-sub.service';
import { TranslationService } from './translation.service';

import { AirReading } from '../models/air-reading.model';
import { CheckedInMapPerson, CheckOutAllRequest, ConfinedSpaceAirReading, ConfinedSpaceEvent,
        ConfinedSpaceItem, ConfinedSpacePermit, EntrantBadgeEvent, Entry, EntryBadgeEvent, EntryCheckin, ItemPerson } from '@weavix/models/src/item/confined-space';
import { AirReadingSubmissionEvent, AnyBadgeEvent, ConfinedSpacePermitEvent, EntryEvent, EventType, WilmaEvent } from '@weavix/models/src/badges/event';
import { GasType } from '../models/gas.model';
import { EntryType } from '../models/item.model';
import { MapItem } from '../models/weavix-map.model';
import { Wilma, WilmaHistory, WilmaImageDetails } from '../models/wilma.model';
import { FormStatus, QuestionType, WorkForm, WorkFormQuestion } from '../models/work-forms.model';

import { intersection, uniqBy } from 'lodash';
import { Topic } from '@weavix/models/src/topic/topic';
import { fromNow } from '../pipes/time-ago.pipe';

export const MAX_WILMA_OFFLINE_TIME = 120000; // 2min - used for removing cameras if images are old and setting to offline if no gas reading within this
export const MAX_WILMA_NETWORK_OFFLINE_TIME = 30000; // For wilma network status checks

@Injectable({
    providedIn: 'root',
})
export class ConfinedSpaceService {

    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
        private translationService: TranslationService,
    ) { }

    public static peopleCheckedInConfinedSpace: {[key: string]: CheckedInMapPerson} = {};
    public static confinedSpaceEvent$: ReplaySubject<ConfinedSpaceItem> = new ReplaySubject(1);
    public static confinedSpaceWilmaEvent$: ReplaySubject<Entry> = new ReplaySubject(1);
    public static confinedSpaceReadingEvent$: ReplaySubject<AirReadingSubmissionEvent> = new ReplaySubject(1);
    private readonly cacheContext: CacheContext = { collection: 'ConfinedSpace', maxAge: 1800000 };

    public static updateItemWithEvent(event: EntryBadgeEvent | EntrantBadgeEvent, item: ConfinedSpaceItem) {
        const entry = item.entries.find(x => x.id === event.entryId);
        if (entry) {
            const updatedPermit = event.confinedSpacePermit;
            const permitIndex = entry.activePermits.findIndex(x => x.id.itemId === updatedPermit.id.itemId && x.id.permitNumber === updatedPermit.id.permitNumber);
            if (permitIndex > -1) {
                if (updatedPermit.closed) {
                    entry.activePermits.splice(permitIndex, 1);
                } else {
                    entry.activePermits[permitIndex] = updatedPermit;
                }
            } else {
                entry.activePermits.push(updatedPermit);
            }

            this.confinedSpaceEvent$.next(item);
        }
    }

    public static updateItemWithPermitCloseEvent(event: ConfinedSpacePermitEvent, item: ConfinedSpaceItem) {
        if (!event.confinedSpacePermitCloseOpen.closed) return;

        (item.entries || []).forEach(e => {
            const activePermits = e.activePermits ?? [];
            activePermits.forEach((ap, index) => {
                const match = ap.id.itemId === item.id && ap.id.permitNumber === event.confinedSpacePermitCloseOpen.permitNumber;
                if (match) e.activePermits.splice(index, 1);
            });
        });
    }

    /**
     * Update entries with wilma assignments
     */
    public static updateItemWithWilmaEvent(event: WilmaEvent, item: ConfinedSpaceItem) {
        const entry = item.entries.find(x => x.id === event.entryId);
        if (entry) {
            entry.wilmaId = event.type === EventType.WilmaUnassigned ? null : event.wilmaId;

            this.confinedSpaceEvent$.next(item);
            this.confinedSpaceWilmaEvent$.next(entry);
        }
    }

    public static emitEntryReadingEvent(event: AirReadingSubmissionEvent) {
        this.confinedSpaceReadingEvent$.next(event);
    }

    /**
     * Get entries with attendants
     */
    public static getActiveEntries(item: ConfinedSpaceItem): Entry[] {
        return (item?.entries ?? []).filter(e => !!ConfinedSpaceService.getEntryAttendants(e, EntryType.AttendantIn).length);
    }

    /**
     * Get total count of all people of entryType across all entries' active permits
     */
    public static getPeopleCount(item: ConfinedSpaceItem, entryType: EntryType) {
        const attendants = ConfinedSpaceService.getAttendants(item, EntryType.AttendantIn);
        if (attendants.length > 0) {
            return ConfinedSpaceService.getPeople(item, entryType).length;
        } else {
            return null;
        }
    }

    /**
     * Get flattened list of all people across all entries' active permits
     */
    public static getPeople(item: ConfinedSpaceItem, type: EntryType): ItemPerson[] {
        return (item.entries || []).reduce((a, b) =>
            uniqBy(a.concat(ConfinedSpaceService.getEntryPeople(b, type)), 'id.personId')
        , []);
    }

    /**
     * Get list of all people for given entry
     */
    public static getEntryPeople(entry: Entry, type?: EntryType): ItemPerson[] {
        return ([entry] || []).reduce((a, b) =>
            a.concat((b.activePermits || []).reduce((c, d) =>
                c.concat((d.people || []).reduce((e, f) => {
                    if (type) {
                        if (f.type === type) e = e.concat(f);
                    } else {
                        e = e.concat(f);
                    }
                    return e;
                }, []))
            , []))
        , []);
    }

    public static getAttendants(item: ConfinedSpaceItem, type: EntryType): ItemPerson[] {
        return (item.entries || []).reduce((a, b) =>
            a.concat(ConfinedSpaceService.getEntryAttendants(b, type))
        , []);
    }

    /**
     * Get list of all attendants for given entry
     */
    public static getEntryAttendants(entry: Entry, type: EntryType): ItemPerson[] {
        return ([entry] || []).reduce((a, b) =>
            a.concat((b.activePermits || []).reduce((c, d) =>
                c.concat((d.attendants || []).reduce((e, f) => {
                    if (f.type === type) e = e.concat(f);
                    return e;
                }, []))
            , []))
        , []);
    }

    /**
     * Get list of all wilma attendants for given entry
     */
    public static getWilmaAttendants(entry: Entry, type: EntryType): ItemPerson[] {
        return ([entry] || []).reduce((a, b) =>
            a.concat((b.activePermits || []).reduce((c, d) =>
                c.concat((d.wilmas || []).reduce((e, f) => {
                    if (f.type === type) e = e.concat(f);
                    return e;
                }, []))
            , []))
        , []);
    }

    /**
     * Checked into means they are either an entrant that is 'in' or an attendant that is 'on'
     */
    public static setPeopleCheckedIntoConfinedSpace(peopleIds: string[], itemMap: Map<string, MapItem>) {
        const allPeopleIn: {[key: string]: CheckedInMapPerson} = {};
        ConfinedSpaceService.peopleCheckedInConfinedSpace = {};
        itemMap.forEach(i => {
            const item = i as ConfinedSpaceItem;
            if (item.entries) {
                item.entries.forEach(e => {
                    e.activePermits.forEach(p => {
                        const attendantsIn = intersection(p.attendants.filter(a => a.type === EntryType.AttendantIn).map(a => a.id.personId), peopleIds);
                        attendantsIn.forEach(a => allPeopleIn[a] = {
                            type: EventType.EntryAttendantIn, itemId: item.id, itemName: item.name, checkinTime: p.attendants.find(x => x.id.personId === a).updated,
                        });
                        const peopleIn = intersection(p.people.filter(a => a.type === EntryType.Entry).map(a => a.id.personId), peopleIds);
                        peopleIn.forEach(a => allPeopleIn[a] = {
                            type: EventType.EntryEnter, itemId: item.id, itemName: item.name, checkinTime: p.people.find(x => x.id.personId === a).updated,
                        });
                    });
                });
            }
        });
        ConfinedSpaceService.peopleCheckedInConfinedSpace = allPeopleIn;
    }

    public static checkedIn(personId: string) {
        return ConfinedSpaceService.peopleCheckedInConfinedSpace[personId];
    }

    /**
     * Based on if the readings are old or not, see constant - {@link MAX_WILMA_OFFLINE_TIME}
     */
    public static wilmaOnline(wilma: Wilma, playbackDate?: number): boolean {
        if (!wilma?.network) return false;
        return fromNow(wilma.network.updated, playbackDate) <= MAX_WILMA_NETWORK_OFFLINE_TIME;
    }

    public static wilmaGasMonitorOnline(wilma: Wilma, playbackDate?: number): boolean {
        if (!wilma?.gasMonitor) return false;
        return fromNow(wilma.gasMonitor.updated, playbackDate) <= MAX_WILMA_OFFLINE_TIME;
    }

    public static hwEventFilter(event: ConfinedSpaceEvent, facilityId: string): boolean {
        if (!(event.checkinEvent || event.entrantEvent || event.wilmaEvent)) return false;
        if (!facilityId) return true;
        if (event.entrantEvent && event.entrantEvent.facilityId === facilityId) return true;
        if (event.checkinEvent && event.checkinEvent.facilityId === facilityId) return true;
        if (event.wilmaEvent && event.wilmaEvent.facilityId === facilityId) return true;
        return false;
    }

    static eventDate(event: ConfinedSpaceEvent) {
        if (event == null) return null;
        const date = event.checkinEvent?.date
            || event.readingEvent?.date
            || event.permitEvent?.date
            || event.wilmaEvent?.date;

        if (date == null) throw new Error(`Unable to find date for confined space event ${event.type}`);
        return date;
    }

    private url = (...pieces: string[]) => `/core/confined-space${pieces ? `/${pieces.join('/')}` : ''}`;
    private urlPermits = (...pieces: string[]) => this.url('permits', ...pieces);

    checkoutAll(component, itemId: string, entryId: string, permitNumber: string, closeDate: Date) {
        const request: CheckOutAllRequest = {
            itemId,
            entryId,
            permitNumber,
            closed: closeDate,
        };
        return this.httpService.post<ConfinedSpacePermit>(component, this.url('checkout-all'), request, this.cacheContext);
    }

    getEntryActivityLogs(component, entryId: string) {
        return this.httpService.get<AnyBadgeEvent[]>(component, this.url(entryId, 'activity'), null, this.cacheContext);
    }

    getPermit(component: any, itemId: string, permitNumber: string) {
        return this.httpService.get<ConfinedSpacePermit>(component, this.urlPermits(itemId, permitNumber), this.cacheContext);
    }

    getPermitsByItem(component: any, itemId: string) {
        return this.httpService.get<ConfinedSpacePermit[]>(component, this.urlPermits(itemId), this.cacheContext);
    }

    getAirReadingSubmission(component, id: string) {
        return this.httpService.get<AirReading>(component, this.urlPermits('reading-submission', id), null, this.cacheContext);
    }

    getLatestReading(component, itemId: string, permitNumber: string, entryId: string) {
        return this.httpService.get<ConfinedSpaceAirReading>(component, this.urlPermits(itemId, permitNumber, entryId, 'latest-reading'), null);
    }

    getEntryActivityForPermit(component, itemId: string, permitNumber: string, entryId: string) {
        return this.httpService.get<AnyBadgeEvent[]>(component, this.urlPermits(itemId, permitNumber, entryId, 'activity'));
    }

    updateWilma(component, itemId: string, wilmaId: string, update: Partial<Wilma>) {
        return this.httpService.post<void>(component, this.url('wilma', 'update'), { itemId, wilmaId, ...update });
    }

    getHistoricalWilmaAssignments(component, itemId: string, date: Date) {
        return this.httpService.get<WilmaHistory>(component, this.url('wilma', itemId), { date: date.toISOString() });
    }

    getConfinedSpaceEvents(component, facilityId: string, from: Date, to: Date) {
        const query = { from: from.toISOString(), to: to.toISOString() };
        return this.httpService.get<ConfinedSpaceEvent[]>(component, this.url('events', 'facility', facilityId), query, this.cacheContext);
    }

    entrantCheckin(component, checkin: EntryCheckin) {
        return this.httpService.post<EntryEvent>(component, this.url('checkin'), checkin);
    }

    getAirReadingForm(): WorkForm {
        return {
            id: null,
            name: this.translationService.getImmediate('forms.airReadings'),
            status: FormStatus.Published,
            questions: this.getReadingQuestions(),
            tags: [],
            currentVersion: 1,
            versions: [],
        };
    }

    private getReadingQuestions(): WorkFormQuestion[] {
        const questions: WorkFormQuestion[] = [];
        Object.values(GasType).forEach(v => {
            questions.push({
                qId: v,
                question: this.translationService.getImmediate(`forms.airReadingQuestions.${v}`),
                type: QuestionType.Number,
                details: {
                    required: true,
                    type: QuestionType.Number,
                },
            });
        });
        return questions;
    }

    public async subscribeConfinedSpace(c: any, itemId: string = '+') {
        return this.pubSubService.subscribe<ConfinedSpaceEvent>(c, Topic.AccountConfinedSpaceEvent, [this.accountService.getAccountId(), itemId]);
    }

    public async subscribeToWilmaReadings(c: any, wilmaId: string = '+') {
        return this.pubSubService.subscribe<Wilma>(c, Topic.AccountWilmaReading, [this.accountService.getAccountId(), wilmaId], true, 0);
    }

    public async subscribeToWilmaVideos(c: any, wilmaId: string = '+', imageIndex: string = '+') {
        return this.pubSubService.subscribe<any>(c, Topic.AccountWilmaCameraVideo, [this.accountService.getAccountId(), wilmaId, imageIndex, '+'], true, 0, true);
    }

    public async subscribeToWilmaThumbnails(c: any, wilmaId: string = '+', imageIndex: string = '+') {
        return this.pubSubService.subscribe<WilmaImageDetails>(c, Topic.AccountWilmaThumbnailDetails, [this.accountService.getAccountId(), wilmaId, imageIndex], true, 0);
    }
}
